import * as React from 'react';
import styled, { keyframes, css, themed } from '../../utils/styled';
import { StandardProps } from '../../common';
export interface SkeletonProps extends StandardProps {
    /**
     * The duration of the shine animation.
     * @default 1.2
     */
    duration?: number;
    /**
     * The width of the skeleton, it could be a number (transformed into px) or a string (e.g.: '75%')
     * @default '100%'
     */
    width?: number | string;
    /**
     * The height of the skeleton, it could be a number (transformed into px) or a string (e.g.: '20px')
     * @default '100%'
     */
    height?: number | string;
    /**
     * Determines if the skeleton should be a circle (e.g.: avatar). The final shape will depend on the `width` and `height` values.
     */
    isCircle?: boolean;
    /**
     * The number of skeletons you want to display.
     * @default 1
     */
    count?: number;
    /**
     * Randomly simulates different text line lengths. This will work only when `count` is greater that 1.
     */
    isText?: boolean;
    /**
     * Determines whether the pulsing animation is active or not. Could be use to stop the animation in case your component has some kind of error or just to have a more simple `Skeleton` component.
     * @default true
     */
    isPulsing?: boolean;
}
const defaultBaseColor = themed(({ theme }) => theme.ui3);
const defaultHighlightColor = themed(({ theme }) => theme.ui2);
const shine = keyframes `
  from {
    background-position: -200px;
  }
  to {
    background-position: 130%;
  }
`;
const Span = styled.span `
  background-color: ${defaultBaseColor};
  background-image: linear-gradient(90deg, ${defaultBaseColor}, ${defaultHighlightColor}, ${defaultBaseColor});
  background-size: 60px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
`;
const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
const formatValue = (value: string | number) => {
    return typeof value === 'number' ? `${value}px` : value;
};
const StyledSpan = styled(Span)<SkeletonProps> `
  ${({ count, duration = 1.2, width = '100%', height = '100%', isCircle, isText, isPulsing = true }) => css `
    animation: ${shine} ${duration}s infinite linear ${isPulsing ? 'running' : 'paused'};
    width: ${count > 1 && isText ? `${randomInt(80, 100)}%` : formatValue(width)};
    height: ${formatValue(height)};
    ${(height && width && isCircle && 'border-radius: 50%') || ''};
  `}
`;
/**
 * The `Sekeleton` component displays a low fidelity UI into which information will be gradually loaded.
 */
export const Skeleton = Object.assign((props => {
    const { count = 1 } = props;
    const skeletons = [];
    for (let i = 0; i < count; i++) {
        skeletons.push(<StyledSpan key={i} count={count} {...props}>
        &zwnj;
      </StyledSpan>);
    }
    return <>{skeletons}</>;
}) as React.FC<SkeletonProps>, { inner: {
        get StyledSpan() { return StyledSpan as typeof StyledSpan; }
    } });
Skeleton.displayName = 'Skeleton';
